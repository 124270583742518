import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Phone from "../../../components/pictos/phone.component"
import { modules, fontSizes, colors } from "../../structure/theme"
import { responsiveHamburger } from "../../navigation/menu/responsiveHamburger"
import { firstLetterUppercase } from "../../../utils/format"
import usePhoneCostNumber from "../../contact/phoneCostNumber.hook"

const BlockPhone = styled.div`
  display: none;

  ${responsiveHamburger`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    flex-shrink: 0;
  `}
`

const Number = styled.div`
  color: ${modules(`navigation.header.phoneNumber`)};
  font-size: ${fontSizes(`large`)};
  font-weight: 900;
  display: flex;
  align-items: center;
`

const PictoContainer = styled.span`
  width: 32px;
  height: 32px;
  background: ${modules(`navigation.header.phoneNumber`)};
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  transform: rotate(90deg);
  margin-right: 8px;
`

const Notice = styled.p`
  font-size: ${fontSizes(`x_small`)};
  color: ${colors(`grey.g700`)};
  margin: 4px 0 0 0;
`

export default function HeaderPhone ({ phoneNumber = `` }) {
  const phoneCostNumber = usePhoneCostNumber()

  if (!phoneNumber) {
    return null
  }

  return (
    <BlockPhone>
      <Number data-testid="cobrand_header_phone">
        <PictoContainer>
          <Phone color="white" size={16} />
        </PictoContainer>
        {phoneNumber}
      </Number>
      {phoneCostNumber && <Notice>{firstLetterUppercase(phoneCostNumber)}</Notice>}
    </BlockPhone>
  )
}

HeaderPhone.propTypes = {
  phoneNumber: PropTypes.string,
}
