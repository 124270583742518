import React, { useMemo } from "react"
import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { Image as ImageStyled } from "../../../components/images.component"
import PropTypes from "prop-types"
import LinkStyled from "../../navigation/link.component"
import HeaderPhone from "./headerPhone.component"
import { MenuWrapper } from "../../navigation/header/menuWrapper.component"
import SwitchLang from "../../translation/switchLang.component"
import { usePageContext } from "../../navigation/pageContext.provider"
import IdAgentLazy from "../../tunnel/agent/idAgentLazy.component"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const Header = styled(MenuWrapper)`
  margin-left: 0;
  padding: 5px 0;

  ${breakpoint(`medium`)`
    padding: 18px 0;
    height: 90px;
  `}
`

const Link = styled(LinkStyled)`
  height: 100%;
`

const ImageWrapper = styled.div`
  display: none;
  height: 100%;

  ${breakpoint(`medium`)`
    display: flex;
  `}
`

const Image = styled(ImageStyled)`
  height: 100%;
  width: auto;
`

const ImageWrapperResponsive = styled.div`
  display: flex;

  ${breakpoint(`medium`)`
    display: none;
  `}
`

function Logos ({ logo, logoResponsive }) {
  return (
    <>
      <ImageWrapper data-testid="cobrand_logo">
        <Image file={logo} />
      </ImageWrapper>
      <ImageWrapperResponsive data-testid="cobrand_logo_responsive">
        <Image file={logoResponsive} />
      </ImageWrapperResponsive>
    </>
  )
}

Logos.propTypes = {
  logo: PropTypes.object.isRequired,
  logoResponsive: PropTypes.object.isRequired,
}

export default function CobrandHeader ({ coBrand, phoneNumber }) {
  const { logo, logoResponsive, coBrandHome = null } = coBrand
  const { hideSalesPhone, disableLogoLink } = usePageContext()
  const hasActiveCobrandHome = useMemo(() => coBrandHome && coBrandHome.active, [coBrandHome])
  const logoWithLink = hasActiveCobrandHome && !disableLogoLink

  return (
    <Container>
      <Header>
        {logoWithLink && (
          <Link route="coBrandHome" params={coBrand} data-testid="cobrand_logo_link">
            <Logos logo={logo} logoResponsive={logoResponsive} />
          </Link>
        )}
        {!logoWithLink && <Logos logo={logo} logoResponsive={logoResponsive} />}
      </Header>
      <SwitchLang />
      <IdAgentLazy />
      {!hideSalesPhone && phoneNumber && <HeaderPhone phoneNumber={phoneNumber} />}
    </Container>
  )
}

CobrandHeader.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  coBrand: PropTypes.shape({
    logo: PropTypes.object.isRequired,
    logoResponsive: PropTypes.object.isRequired,
    slug: PropTypes.string.isRequired,
    coBrandHome: PropTypes.shape({
      active: PropTypes.bool,
    }),
  }).isRequired,
}
