import React, { memo, useEffect, useState } from "react"
import styled, { css } from "styled-components"
import { usePageContext } from "../navigation/pageContext.provider"
import Link from "../navigation/link.component"
import { colors } from "../structure/theme"
import { useLocation } from "../navigation/location.provider"
import { getCurrentI18n } from "./i18n"
import { useLocalStorage } from "@homeserve/react-storage-provider"
import { localeToLang } from "./translate.utils"
import { flexCenter } from "../structure/theme/styles.component"

const Wrapper = styled.div`
  ${flexCenter};
  padding: 0 5px;
`

const labelStyle = css`
  text-transform: uppercase;

  :not(:last-child):after {
    content: "|";
    padding: 0 4px;
    color: ${colors(`grey.g650`)};
  }
`

const LangLink = styled(Link)`
  text-decoration: none;
  color: ${colors(`grey.g650`)};
  ${labelStyle};
`

const LangText = styled.span`
  color: ${colors(`primary`)};
  font-weight: 900;
  ${labelStyle};
`

function SwitchLang () {
  const { pathname, search } = useLocation()
  const { pageContextReady, translatePages, translatePageQuery } = usePageContext()
  const { write } = useLocalStorage()
  const [visible, setVisible] = useState(!!translatePages)

  useEffect(() => {
    setVisible(
      pageContextReady &&
        translatePages &&
        (!translatePageQuery || (translatePageQuery && search.includes(translatePageQuery))),
    )
  }, [pageContextReady, translatePages, translatePageQuery, search])

  if (!visible || !pageContextReady) {
    return null
  }

  function onClick (locale, lang) {
    getCurrentI18n().i18n.changeLanguage(locale, () => {
      write(`lang`, lang)
    })
  }

  const safePathname = process.env.PATH_PREFIX ? pathname.replace(process.env.PATH_PREFIX, ``) : pathname

  return (
    <Wrapper>
      {Object.entries(translatePages).map(([locale, path]) => {
        const lang = localeToLang(locale)

        if (path === safePathname) {
          return <LangText key={locale}>{lang}</LangText>
        }

        return (
          <LangLink key={locale} href={path} onClick={() => onClick(locale, lang)} data-testid={`switch_lang_${lang}`}>
            {lang}
          </LangLink>
        )
      })}
    </Wrapper>
  )
}

export default memo(SwitchLang)
