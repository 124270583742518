import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"

export const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  position: static;
  height: 60px;
  margin-left: auto;

  ${breakpoint(`medium`)`
    height: ${process.env.PARTNER === `hsv` ? 105 : 83}px;
    margin: 0;
    width: 100%;
  `}
`
